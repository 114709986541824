// Colors
$tch-gold: #fdb525;
$tch-gold-light: #ffdc96;
$tch-gold-dark: #d89104;
$tch-gold-darker: #aa7100;
$tch-gray: #78787a;
$tch-gray-light: lighten($tch-gray, 10%);
$tch-black: #231f20;
$tch-teal: #169898;
$tch-teal-dark: darken($tch-teal, 8%);
$tch-purple: #492aae;
$tch-blue: #4567b6;
$tch-blue-dark: darken($tch-blue, 8%);
$tch-white: #ffffff;
$tch-red: #ff0000;
$tch-red-lightest: #fcc;
$tch-green: #03ad03;
$tch-green-light: lighten($tch-green, 30%);
$tch-orange: #f98729;

$color-primary: $tch-gold;
$color-secondary: $tch-blue;

// Fonts
$body-font: 'Montserrat', sans-serif;
$display-font: 'Libre Baskerville', serif;
$sans-font: 'Open Sans', sans-serif;
$fa-font-path: '../../bower_components/font-awesome/fonts';

// Padding
$padding-small: 2rem;
$padding-medium: 5rem;
$padding-large: 10rem;
$padding-xlarge: 15rem;
$padding-xxlarge: 20rem;
$mobile-gutter: .75rem !important;

// CSS transitions
$default-transition-time: .2s;
$default-transition-easing: ease-in-out;
$default-transition: all $default-transition-time $default-transition-easing;

.calendar {

  .calendar-header {
    display: block;

    > .row {
      margin-bottom: 1rem;
    }
  }

  .filter-type-form {
    position: relative;
    z-index: 10;
    top: .8rem;
    @include respond(mobile) {
      top: auto;
      left: 50%;
      @include translate(-50%, 0);
      margin-bottom: 1rem;
    }

    .btn {
      @include respond(tablets) {
        font-size: 1.5vw;
      }
      @include respond(mobile) {
        font-size: .9em;
      }
    }
  }

  .month-select-form {
    @include respond(mobile) {
      padding: 0;
      padding-left: $mobile-gutter;
    }

    li.current-month {

      a {
        color: $tch-gold-dark;
      }

      &.appended {
        border-top: 1px solid lighten($tch-gray-light, 20%);

        a {
          font-size: .9em;

          i {
            vertical-align: middle;
            font-size: 1.5em;
            opacity: .5;
          }
        }
      }
    }

    li.selected-month {
      background-color: lighten($tch-blue, 40%);

      a {
        color: $tch-black;
      }
    }
  }

  .calendar-views {
    position: relative;
    top: .4rem;
    padding: 0 2rem;
    @include respond(mobile) {
      padding: 0;
      padding-right: $mobile-gutter;
    }

    a {
      display: inline-block;
      line-height: .8em;
      margin: 0 .15rem;
      text-align: center;
      color: $tch-gray-light;

      small {
        display: block;
        font-size: 60%;
        text-transform: uppercase;
      }

      &:hover,
      &:active,
      &:focus {
        color: $tch-black;
      }

      &.active {
        color: $tch-blue;
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 1px;
    @include respond(mobile) {
      display: block;
      padding: 0 $mobile-gutter;
      overflow-x: scroll;
    }

    td, th {
      padding: 0;
      border-radius: .25rem;

      .cell-wrapper {
        padding: 1rem .5rem;
      }
    }

    thead {
      @include respond(mobile) {
        display: block;
        width: 100%;
      }

      th {
        color: $tch-white;
        font-size: 1.2vw;
        text-align: center;
        text-transform: uppercase;
        background-color: $tch-black;
        @include respond(mobile) {
          font-size: 1em;
        }
      }
    }

    tbody {
      @include respond(mobile) {
        display: block;
        width: 100%;
      }

      tr {
        @include respond(mobile) {
          display: block;
          width: 100%;
        }
      }

      th {
        @include respond(mobile) {
          display: block;
          width: 100%;
        }

        &.no-events {
          text-align: center;
          font-family: $sans-font;
          font-weight: 500;
          background-color: lighten($tch-gray-light, 35%);

          .cell-wrapper {
            padding: 3rem;
          }
        }

        &.hour-label {
          width: 10%;
          line-height: 1.25em;
          text-align: right;
          font-family: $sans-font;
          color: $tch-gray;
          background-color: lighten($tch-gray-light, 25%);
          @include respond(mobile) {
            width: 100%;
          }

          .time {
            display: block;
            font-size: 1.5em;
            font-weight: 500;
          }

          .meridian {
            display: block;
            font-size: .9em;
            font-weight: 300;
          }
        }
      }
    }

    td {
      width: 14.2%;
      vertical-align: top;
      background-color: lighten($tch-gray-light, 35%);
      @include respond(mobile) {
        display: block;
        width: 100%;
      }

      .cell-wrapper {
        padding: .25rem .5rem 1rem;
      }

      .date-day {
        font-family: $sans-font;
        font-size: 1.8em;
        font-weight: 900;

        .date-mobile {
          display: none;
          @include respond(mobile) {
            display: inline-block;
            margin-right: .25em;
            text-transform: uppercase;
            font-size: .6em;
            font-weight: 300;
          }
        }

        a {
          color: $tch-black;

          &:hover,
          &:active,
          &:focus {
            color: $tch-blue;
          }
        }
      }

      em {
        display: inline-block;
        line-height: normal;
        font-family: $sans-font;
        font-size: .7em;
        color: $tch-gray-light;
        @include respond(mobile) {
          font-size: .9em;
        }
      }

      .encounter-item {
        margin-top: .5rem;
        padding-top: .5rem;
        font-size: .8em;
        border-top: 1px solid lighten($tch-gray-light, 20%);

        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: 0;
        }

        a {
          display: block;
          @include respond(mobile) {
            font-size: 1.5em;
          }

          > span {
            display: block;
            line-height: normal;
          }

          .start-time {
            font-size: .85em;
            color: $tch-gray;
            font-family: $sans-font;
          }
        }
      }

      .btn-view-more {
        display: block;
        margin-top: 1rem;
        padding: .2rem 1rem;
        font-size: .7em;
        border-width: 1px;
        @include respond(mobile) {
          padding: .5rem 1rem;
          font-size: .9em;
        }
      }

      &.today {
        background-color: $tch-gold-light;
      }

      &.non-current {
        background-color: lighten($tch-gray-light, 25%);

        .date-day {

          a {
            color: $tch-gray-light;;

            &:hover,
            &:active,
            &:focus {
              color: $tch-blue;
            }
          }
        }
      }

      &.hour-event-block {
        width: 90%;
        @include respond(mobile) {
          width: 100%;
        }

        .cell-wrapper {
          padding: .5rem 1rem;
        }

        ul {
          margin: 0;
        }
      }
    }

    tfoot {
      @include respond(mobile) {
        display: block;
        width: 100%;
      }

      tr {
        @include respond(mobile) {
          display: block;
          width: 100%;
        }
      }

      th {
        padding: 2rem 0;
        background-color: transparent;
        @include respond(mobile) {
          display: block;
          width: 100%;
        }
      }
    }

    &.monthly,
    &.weekly {

      thead {
        @include respond(mobile) {
          display: none;
        }
      }

      tbody {

        td {
          margin: 1rem 0;
        }
      }

      .date-day {
        @include respond(mobile) {
          border-bottom: 1px solid $tch-gray;
        }
      }
    }

    &.daily {

      thead, tr, th {
        @include respond(mobile) {
          display: block;
          width: 100%;
        }
      }

      th.hour-label {
        text-align: center;

        span {
          display: inline;
        }
      }
    }
  }
}

ul.notices-list {

  li.notice {
    margin-bottom: 1rem;
    padding: 1rem;
    @include respond(mobile) {
      text-align: center;
      padding-left: $mobile-gutter;
      padding-right: $mobile-gutter;
    }

    &:not(.alert) {
      padding-left: 0;
      padding-right: 0;
      color: $tch-gray;
      border-bottom: 1px dashed lighten($tch-gray-light, 30%);

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }

    & > i {
      position: relative;
      top: .3em;
      margin-right: 1rem;
      color: $tch-gold;
    }

    .btn {
      margin-left: 1rem;
      @include respond(mobile) {
        margin: 1rem 0;
        width: 60%;
      }
    }
  }
}

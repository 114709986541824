#app-header {
  position: fixed;
  z-index: 998;
  top: 0;
  width: 100%;
  background-color: rgba(255,255,255,0.9);

  .row {
    margin-bottom: 0;
    @include respond(mobile) {
      .col {

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  nav#app-nav {
    margin: 0;
    line-height: normal;
    background: none;
    box-shadow: none;

    .app-nav-trigger {
      display: inline-block;
      margin: 0 auto;
      padding: 0 1rem;
      color: $tch-black;

      &:hover,
      &:active,
      &:focus {
        color: $tch-blue;
      }

      i {
        font-size: 2.5em;
      }
    }
  }

  .app-title {
    display: block;
    text-align: center;

    img {
      height: 3rem;
      margin: .65rem auto 0 auto;

      &.logo {
        @include respond(mobile) {
          display: none;
        }
      }

      &.logo-symbol {
        @include respond(mobile) {
          display: block !important;
        }
      }
    }
  }

  .current-user {
    text-align: right;

    .user-name {
      margin-right: .5rem;
    }

    .dropdown-button {
      display: inline-block;
      padding: 1.45rem 1rem;
      text-align: center;
      border-radius: 0;
      @include respond(mobile) {
        padding: 1.45rem 1rem;
      }

      &:hover,
      &:active,
      &:focus {

        i,
        .user-name {
          color: $tch-blue;
          @include transition($default-transition);
        }
      }

      &.active {
        background-color: $tch-white;
      }

      i {
        position: relative;
        top: 2px;
        color: $tch-gray-light;
        font-size: .9em;
        @include respond(mobile) {
          color: $tch-gray;
          font-size: 1.5em;
        }

        &.fa-user,
        &.fa-user-md {
          font-size: 1.5em;
          @include respond(mobile) {
            font-size: 2em;
          }
        }
      }
    }

    .dropdown-content {
      $border-color: lighten($tch-gray-light, 30%);
      position: relative;
      max-height: auto;
      padding: 1rem;
      font-size: 1.2em;
      border: 1px solid $border-color;
      background-color: $tch-white;
      overflow: visible;
      @include respond(mobile) {
        min-width: 200px;
      }

      $pointer-size: 7px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: -#{$pointer-size * 2};
        left: 50%;
        @include translate(-50%, 0);
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-width: $pointer-size;
        border-bottom-color: $tch-white;
        @include respond(mobile) {
          left: auto;
          right: 1.75rem;
        }
      }

      &::after {
        z-index: -1;
        top: -#{($pointer-size * 2) + 1};
        border-bottom-color: $border-color;
      }

      .user-name {
        margin: 0;
        padding: 0;
        color: $tch-gray-light;
        text-align: center;
      }

      li {
        min-height: auto;
        margin-bottom: 1rem;
        background-color: transparent;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .user-name {
      line-height: normal;
      font-size: .9em;
      color: $tch-gray;
      text-transform: uppercase;
    }

    .btn.user-action {
      margin-top: .25rem;
      padding: .5rem;
      font-size: .9em;
      box-shadow: none;
      border-width: 1px;

      &.user-status {
        padding: .5rem .75rem;
      }
    }
  }
}

.about {

  strong,
  a {
    font-size: 1.3em;
  }

  i {
    position: relative;
    top: .25em;
    color: $tch-gray-light;
  }
}

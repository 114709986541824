// Proxima Nova - MU body font
@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-black-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-bold-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-semibold-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-sbolditalic-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-sbolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-regular-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-regularitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-light-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/proximanova-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/proximanova-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}


// Janson - MU display font
@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-bol-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-bol-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-bolita-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-bolita-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-med-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-med-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-medita-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-medita-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-reg-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-reg-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-regita-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-regita-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-lig-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-lig-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'janson';
    src: url('../fonts/urw_-_jansonurw-ligita-webfont.woff2') format('woff2'),
         url('../fonts/urw_-_jansonurw-ligita-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

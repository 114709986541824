table {

}

ul.collection {
  border: 0;
  overflow: visible;

  li.collection-item {
    padding: .5rem 0;
    border-bottom: 0;

    .btn {
      min-width: 30%;
      @include respond(mobile) {
        width: 100%;
        font-size: 5vw;
      }
    }
  }
}

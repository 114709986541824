.modal {
  z-index: 100002 !important;
  max-height: 90%;
  width: 65%;
  -webkit-overflow-scrolling: touch;
  @include respond(mobile) {
    width: 90%;
  }

  .modal-header {
    margin-bottom: 0;
    padding: 1rem;
    text-align: center;
    background-color: lighten($tch-gray-light, 35%);

    .row {
      margin: 0;
    }

    .col > i {
      margin-right: .5rem;
      font-size: 1.9em;
      color: lighten($tch-gray-light, 30%);
      @include respond(mobile) {
        display: none;
      }
    }

    .modal-title {
      display: inline;
      margin: 0;
      font-size: 1.5em;
      color: lighten($tch-gray-light, 10%);
    }

    .modal-close {
      display: none;
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 2rem;
      width: 2rem;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        line-height: normal;
        font-size: 1.3rem;
        opacity: 0.75;
      }
    }
  }

  .modal-content {
    padding: 0;
  }

  .modal-main {
    padding: $padding-small 0;
    @include respond(mobile) {
      padding: $padding-small;
    }

    .container {
      @include respond(mobile) {
        margin: 0;
        width: auto;
      }
    }
  }

  &.modal-fixed-footer {

    .modal-main {
      padding-bottom: 3rem;
    }

    .modal-footer {
      z-index: 1;
      height: auto;
      padding: 1rem 0;
      text-align: center;
      border-top: 1px solid rgba(0,0,0,0.05);
      background-color: lighten($tch-gray-light, 40%);

      .btn {
        float: none;
        margin: 0;
      }
    }
  }

  ul[class*="-list"] {
    margin: 0 auto;
  }

  .appointments-list.select-list {
    width: 70%;
    @include respond(mobile) {
      width: 100%;
    }

    li a.btn {
      @include respond(mobile) {
        font-size: 4vw !important;
      }
    }
  }

  .button-list {
    text-align: center;

    .btn {
      width: 75%;
      @include respond(mobile) {
        width: 100%;
      }
    }
  }
}

.lean-overlay {
  z-index: 100001 !important;
  top: 0;
  height: 100%;
}

/* Modal-specific */

#add-dependent {
  min-height: 28rem;

  .picker {

    .picker__date-display {
      display: none;
    }
  }
}

#enter-phone-number {

  .modal-header {
    margin-bottom: 0;
  }
}

#datetime-picker {

  .days-container {

    header {
      position: relative;

      .paginator {
        display: block;
        position: absolute;
        top: 50%;
        @include translate(0, -22.5px);

        &.disabled {
          cursor: default;

          i {
            color: lighten($tch-gray-light, 30%);
          }
        }

        i {
          font-size: 3em;
        }

        &.prev-day {
          left: 0;
        }

        &.next-day {
          right: 0;
        }
      }
    }
  }

  .day-title {
    padding: .5rem 2rem;
    text-align: center;
    font-size: 2em;
    font-family: $sans-font;
    font-weight: 300;
    text-transform: uppercase;
    border-bottom: 1px solid lighten($tch-gray-light, 30%);
  }
}

#appointment-photos {
  background: transparent;
  box-shadow: none;

  &.modal {
    height: 80%;
  }

  .modal-header {
    padding: 0;
    background-color: transparent;

    .modal-close {
      display: block;
    }
  }

  .carousel.carousel-slider {
    height: 100% !important;

    a.carousel-item {
      background-size: contain;
      background-position: 50% 0;
      background-repeat: no-repeat;
    }
  }
}

#problems-with-call {

  .modal-header {
    .modal-close {
      display: block;
    }
  }

  .modal-footer {
    text-align: center;
    margin-bottom: 2rem;
  }

  .modal-footer .btn {
    float: none;
  }

}

#export-appointment {

  .cal-export {
    font-size: 1em;
    @include respond(mobile) {
      width: 100%;
      padding: 1rem;
      font-size: 4vw;
    }
  }
}

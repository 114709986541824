.pt-scene {
  // Basic styles for an animated element
  .pt-el {
    animation-duration: 0.25s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;

  }

  // An element that fades in
  .pt-el--fade-in {
    animation-name: fade-in;
  }

  // An element that fades in and slides up
  .pt-el--fade-in-up {
    animation-name: fade-in-up;
  }

  // An element that fades in and slides from the right
  .pt-el--fade-in-right {
    animation-name: fade-in-right;
  }

  &.is-exiting {
    .pt-el {
      animation-direction: alternate-reverse;
    }
  }
}

/* Keyframes */

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    /*opacity: 1;
    transform: none;*/
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    /*opacity: 1;
    transform: none;*/
  }
}

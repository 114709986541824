.tabs {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 48px;
  background-color: $tabs-bg-color;
  margin: 0 auto;
  width: 100%;
  white-space: nowrap;

  .tab {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: block;
    float: left;
    text-align: center;
    line-height: 48px;
    height: 48px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: .8px;
    width: 15%;
    min-width: 80px;

    a {
      color: $tabs-text-color;
      display: block;
      width: 100%;
      height: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      transition: color .28s ease;
      &:hover {
        color: lighten($tabs-text-color, 20%);
      }
    }

    &.disabled a {
      color: lighten($tabs-text-color, 20%);
      cursor: default;
    }
  }
  .indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $tabs-underline-color;
    will-change: left, right;
  }
}

.tabs-container {
  $tabsHeight : 64px;
  $tabsBorder : 1px solid lighten($tch-gray-light, 20%);
  $tabsBgColor: $tch-white;
  @include respond(mobile) {
    margin-left: $mobile-gutter;
    margin-right: $mobile-gutter;
  }

  & > form {
    @include respond(mobile) {
      padding: 0 !important;
    }
  }

  .tabs {
    height: $tabsHeight;
    margin-top: 3rem;
    @include respond(mobile) {
      white-space: normal;
    }

    &.icon-tabs {

      i {
        font-size: 2em;
        line-height: 1.5em;
        @include respond(mobile) {
          font-size: 9vw;
        }
      }
    }

    .indicator {
      bottom: auto;
      top: 0;
    }

    .tab {
      line-height: $tabsHeight;
      height: $tabsHeight;
      @include respond(mobile) {
        line-height: normal;
      }

      &:first-child {

        a {
          border-left: $tabsBorder;
        }
      }

      a {
        position: relative;
        color: $tch-blue;
        font-size: 1.3em;
        border: $tabsBorder;
        border-left: 0;
        background-color: rgba(69, 103, 182, .05);
        @include transition(all $default-transition-easing $default-transition-time);
        @include respond(mobile) {
          padding: .5rem;
          font-size: 3vw;
        }

        &:hover, &:active, &:focus {
          color: $tch-blue-dark;
        }

        &.active {
          font-weight: 700;
          color: $tch-gray;
          border-top: 0;
          border-bottom: 0;
          background-color: $tabsBgColor;
        }

        span {
          @include respond(mobile) {
            position: absolute;
            top: 50%;
            left: 0;
            @include translate(0, -50%);
            width: 100%;
          }
        }
      }

      &.disabled {

        a {
          color: $tch-blue;
          opacity: 0.5;
        }
      }
    }

    .indicator {
      background-color: $tch-blue;
      opacity: 0.5;
    }
  }

  .tab-content {
    margin: 0 0 1rem 0;
    padding: 2rem;
    border: $tabsBorder;
    border-top: 0;
    background-color: $tabsBgColor;
    overflow: hidden;

    .page-header {
      display: none;
    }

    /* Nested tabs */
    .tabs {
      margin-top: 0;
    }
  }
}

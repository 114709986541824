body.physician {

  .main-container {
    @include respond(mobile) {
      padding-bottom: $padding-medium;
    }
  }

  &.home .status-switcher {
    bottom: 9rem;
    @include respond(mobile) {
      bottom: 5rem;
    }
  }

  .time-segment-title {
    position: relative;
    margin: 2rem 0;
    color: $tch-gray-light;
    font-size: 1.5em;
    font-family: $sans-font;
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: .75rem;
      height: .5rem;
      width: 40%;
      background-color: $tch-gray-light;
      opacity: 0.15;
      @include respond(mobile) {
        width: 35%;
      }
    }

    &::before {
      right: 60%;
      @include respond(mobile) {
        right: 65%;
      }
    }

    &::after {
      left: 60%;
      @include respond(mobile) {
        left: 65%;
      }
    }
  }

  .appointments-queue {
    padding-bottom: 7rem;
    @include respond(mobile) {
      padding-bottom: $padding-medium;
    }

    .appointments-list {
      margin-left: 0 !important;
      margin-right: 0 !important;

      .appointment {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
        margin: 1px 0;
        padding: 0;

        &.status--in-session {

          a.btn {
            height: 10rem;
            @include transition($default-transition);

            .appointment-status {

              i,
              .status-title {
                color: $tch-green;
                font-weight: 700;
              }

              .session-physician {
                display: block;
                color: $tch-gray;
                font-size: 60%;
                @include respond(mobile) {
                  margin: .25rem 0;
                  font-size: 50%;
                  border-radius: .25rem;
                  background-color: #eee;
                }

                i {
                  color: $tch-gray;
                  @include respond(mobile) {
                    display: none;
                  }
                }
              }
            }
          }
        }

        a.btn {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          position: relative;
          margin: 0;
          padding: 0;
          border: 0;
          @include respond(mobile) {
            left: 0;
          }

          .row {
            width: 100%;
            margin: 0;
          }

          .col {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            height: 100%;
            padding: 0;
          }
        }

        .appointment-info {
          padding: 1rem $mobile-gutter;

          .patient-name {
            margin: 0;
            font-size: 1.25em;
            font-weight: 400;
            color: $tch-black;
            @include respond(mobile) {
              font-size: 5.5vw;
            }
          }

          .appointment-encounter-type {
            @include respond(mobile) {
              font-size: 5vw;
            }
          }

          .symptoms {
            font-family: $sans-font;
            font-weight: 400;
            font-size: .8em;
            font-style: italic;
            color: $tch-gray;
          }

          @include respond(mobile) {
            .truncate {
              max-width: 230px;
            }
          }
        }

        .appointment-status {
          position: relative;
          height: 100%;
          @include transition($default-transition);
          align-items: center;
          justify-content: center;

          .appointment-status-content {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 1rem .5rem;
            text-transform: uppercase;
            text-align: center;
          }

          i {
            margin: .5rem 0;
            font-size: 1.5em;
            width: 1.5em;
            color: $tch-white;
            opacity: 1;
            @include respond(mobile) {
              margin: 0;
              font-size: 1.25em;
            }
          }

          .status-title {
            margin: .5rem 0;
            font-family: $sans-font;
            font-size: .75em;
            font-style: normal;
            color: $tch-white;
            @include respond(mobile) {
              margin-top: .15rem;
              font-size: 3vw;
            }
          }

          .status-datetime {
            display: inline-block;
            margin-top: .25rem;
            font-family: $sans-font;
            font-weight: 300;
            color: $tch-black;
            @include respond(mobile) {
              font-size: 4vw;
            }

            small {
              font-size: 100%;
              font-weight: 300;
            }
          }
        }

        &.cancelable {

          .btn-cancel-encounter {
            height: auto;
            position: absolute;
            top: 50%;
            left: -4rem;
            padding: .5rem;
            @include translate(0, -50%);
            font-size: .8em;
            text-align: center;
            color: $tch-red;
            @include respond(mobile) {
              display: block !important;
              top: 0;
              height: 100%;
              padding: 3rem .5rem;
              font-size: .8em !important;
              @include translate(0, 0);
              background-color: white;
              border-radius: 0;
            }

            i {
              display: block;
              position: static;
              top: auto;
              left: auto;
              width: auto;
              margin-bottom: .25rem;
              color: $tch-red;
              text-align: center;
              @include translate(0, 0);
            }
          }

          &.cancel-active {

            @include respond(mobile) {
              a.btn.btn-appointment {
                left: 4rem;
              }

              a.btn.btn-cancel-encounter {
                left: 0;
              }
            }
          }
        }
      }
    }

    .load-more {
      position: relative;
      left: 50%;
      @include translate(-50%, 0);
      margin: $padding-small 0;
    }
  }

  .physician-list {
    @include respond(mobile) {
      padding-right: $mobile-gutter;
      padding-left: $mobile-gutter;
    }

    & > li {
      @include respond(mobile) {
        margin-right: $mobile-gutter;
        margin-left: $mobile-gutter;
      }
    }

    .chip {
      display: block;
      margin-bottom: 1rem;

      i {
        float: right;
        font-size: 1.5em;
        line-height: 1.7em;
      }
    }
  }

  .view-appointment {

    .btn.confirm-appointment {

      &.assigned {
        color: $tch-gray;
        background-color: lighten($tch-gray-light, 20%);

        i {
          color: $tch-green;
        }
      }
    }
  }

  .patient-header {
    position: relative;
    height: 20rem;
    padding-top: 3rem;
    background-color: lighten($tch-gray-light, 10%);
    overflow: hidden;

    .header-i {
      position: absolute;
      z-index: 0;
      left: 50%;
      bottom: -5rem;
      @include translate(-50%, 0);
      color: $tch-gray-light;
      font-size: 20rem;
      @include respond(mobile) {
        color: lighten($tch-gray-light, 5%);
      }
    }

    .container {
      position: relative;
      height: 100%;
    }

    .title.patient-name {
      position: absolute;
      left: 0;
      bottom: 1rem;
      line-height: 1em;
      color: $tch-white;
      font-family: $sans-font;
      font-weight: 300;
      @include respond(mobile) {
        bottom: 0;
        font-size: 12vw;
      }
      .dependent-of,
      .employee-of {
        color: black;
        font-size: 0.8em;
      }
    }

    .back-link {
      position: absolute;
      top: 3rem;
      left: 0;
      padding: .5rem;

      i {
        color: $tch-blue;
        font-size: 2em;
      }
    }

    .btn-create-encounter {
      position: absolute;
      top: 3.5rem;
      right: 0;
      @include respond(mobile) {
        top: 3rem;
        padding: .5rem;
        line-height: 1em;
      }

      i {
        @include respond(mobile) {
          font-size: 2em;
        }
      }
    }
  }

  .patient-detail {
    overflow: hidden;

    .table--patient-info {
      margin-bottom: 2rem;

      th,
      td {
        font-size: 1.2em;
        @include respond(mobile) {
          padding-left: $mobile-gutter;
          padding-right: $mobile-gutter;
        }
      }

      th {
        @include respond(mobile) {
          text-align: left;
        }
      }

      .patient-age {
        font-family: $sans-font;
      }
    }
  }

  .past-encounters {
    margin-bottom: 2rem;

    .appointments-list {
      p {
        display: inline-block;
        padding: 0;
        margin: .5rem .5rem .5rem 0;
      }
    }
  }

  .archives {

    .appointments-list {
      p {
        display: inline-block;
        padding: 0;
        margin: .5rem .5rem .5rem 0;
      }
    }
  }

  /* Patient Medical History */

  #medical-history,
  #allergies-medicine {

    .input-container {
      margin-bottom: -1px;
    }

    .input-field {
      margin-top: 0;
    }

    .switch-title {
      display: inline-block;
      margin: 0;
    }
  }

  /* Create Encounter */

  .create-encounter {

    form {
      margin: 3rem 0 0 0;
    }

    .picker__box {
      margin-top: 2rem;
    }

    .picker__date-display {
      display: none;
    }

    .form-footer {
      overflow: hidden;
      padding: 3rem 0;
    }
  }
}

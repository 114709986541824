.user-settings {
  .tabs-container .tabs .tab a {
    font-size: inherit;
  }
}

#medical-history,
#allergies-medicine {

  & > .row > .col {
    @include respond(mobile) {
      padding-top: .75rem;
      border-top: 1px solid lighten($tch-gray-light, 30%);
    }

    &:first-child {
      border-right: 1px dashed lighten($tch-gray-light, 30%);
      @include respond(mobile) {
        border-right: 0;
        border-top: 0;
      }
    }
  }

  .input-container {
    margin-bottom: 1rem;
    padding: 1rem;
    overflow: hidden;

    &.select-container {
      overflow: visible;
    }
  }

  .input-field {
    margin-top: 0;
    @include respond(mobile) {

      .title,
      &.switch {
        text-align: center;
      }
    }

    .title {
      margin-top: 0;
      font-size: 1.2em;
      color: $tch-gray;
    }

    &.switch {
      margin-bottom: 0;

      .switch-title {
        margin-bottom: 0;
      }

      label {
        position: relative;
        z-index: 1;
      }

      & > .col:last-child {
        text-align: right;
        @include respond(mobile) {
          margin-top: 1rem;
          text-align: center;
        }
      }
    }
  }

  .pcp-title {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 10px;
    padding: 0;
  }
}

form {

  & > .title {
    margin-bottom: 2rem;
  }

  .input-container {
    margin-bottom: 2rem;
    padding: $padding-small;
    border: 1px solid lighten($tch-gray-light, 30%);
  }

  .input-field {

    label {
      color: lighten($tch-gray, 15%);
      font-weight: 400;
      font-family: $sans-font;
      display: block;

      &.active {
        @include translate(0, -140%);
      }
    }

    input,
    select,
    textarea {
      font-family: $sans-font;
      font-weight: 700;
      font-size: 1.25em;
      border-bottom: 1px solid rgba(0,0,0,0.26);

      &:disabled,
      &[disabled],
      &[disabled="disabled"],
      &[readonly],
      &[readonly="readonly"] {
          border-bottom-style: dashed;
      }

      &:disabled,
      &[disabled],
      &[disabled="disabled"] {
        color: $tch-gray-light;
        border-color: rgba(0,0,0,0.15);
      }

      &[readonly],
      &[readonly="readonly"] {
        color: $tch-gray;
      }

      &:focus:not([readonly]) + label {
        color: $tch-blue;
      }

      &:focus {
        background-color: transparent !important;
        border-bottom: 1px solid $tch-blue !important;
        box-shadow: 0 1px 0 0 $tch-blue !important;
      }

      &.valid {
        border-bottom: 1px solid $tch-green !important;
        box-shadow: 0 1px 0 0 $tch-green !important;
      }

      &.invalid {
        color: $tch-red !important;
        border-bottom: 1px solid $tch-red !important;
        box-shadow: 0 1px 0 0 $tch-red !important;
      }

      // placeholders
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: lighten($tch-gray-light, 15%);
        font-weight: 400;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: lighten($tch-gray-light, 15%);
        font-weight: 400;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: lighten($tch-gray-light, 15%);
        font-weight: 400;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: lighten($tch-gray-light, 15%);
        font-weight: 400;
      }
    }

    input[type="text"],
    input[type="search"] {
      padding-left: 0;
    }

    select.browser-default {
      border-top: 0;
      border-right: 0;
      border-left: 0;

      &:focus {
        outline: 0;
      }
    }

    .prefix {
      top: .5rem;

      &.active {
        color: $tch-blue;
      }
    }

    .btn.float {
      position: absolute;
      top: 0;
      right: 0;
      font-size: .8em;
      padding: .75rem 1rem;
    }

    ul.select-dropdown,
    .select-wrapper {

      .dropdown-content li {

        & > a,
        & > span {
          color: $tch-blue;
        }
      }
    }

    .select-wrapper {

      input.select-dropdown {
        font-family: $sans-font;
        font-weight: 700;
        font-size: 1.25em;
        color: $tch-black;
        border-bottom: 1px solid rgba(0,0,0,0.26);
      }
    }

    &.input-field-submit {
      @include respond(mobile) {
        text-align: center;
      }

      input[type="submit"],
      button[type="submit"] {
        @include respond(mobile) {
          float: none !important;
          width: 60%;
        }
      }
    }
  }

  .input-description {
    position: relative;
    top: -1.25rem;
    color: $tch-gray-light;
    font-weight: 300;
  }

  // Switches

  .switch {
    $switch-bg-color: $tch-blue;
    $switch-checked-lever-bg: darken($tch-blue, 20%);
    $switch-unchecked-bg: lighten($tch-gray-light, 30%);
    $switch-unchecked-lever-bg: $tch-gray;

    &.input-field {
      margin-bottom: 2rem;
    }

    .switch-title {
      font-size: 1.2em;
    }

    label {
      position: static;
      top: auto;
      left: auto;
      font-size: .8em;
    }

    .lever {
      width: 4.5rem;
      height: 1.5rem;
      padding: 0 .5rem;
      background-color: $switch-unchecked-lever-bg;

      span {
        line-height: 2em;
        font-size: .9em;
        color: lighten($tch-gray-light, 20%);
        text-transform: uppercase;
      }

      &::after {
        top: -.3rem;
        left: -1px;
        width: 2rem;
        height: 2rem;
        background-color: $switch-unchecked-bg;
        box-shadow: 0 0 1px 2px rgba(0,0,0,0.15);
      }
    }

    input[type=checkbox] {

      &:checked + .lever {
        background-color: $switch-checked-lever-bg;

        span {
          color: lighten($tch-blue, 20%);
        }

        &::after {
          background-color: $switch-bg-color;
          left: 2.5rem;
        }
      }

      // Switch active style
      &:checked:not(:disabled) ~ .lever:active::after,
      &:checked:not(:disabled).tabbed:focus ~ .lever::after {
        box-shadow: 0 1px 3px 1px rgba(0,0,0,.4), 0 0 0 15px transparentize($switch-bg-color, .9);
      }
    }
  }

  // Range

  .range {

    &.input-field {
      margin-bottom: 4rem;
    }

    input[type=range] {
      @include respond(mobile) {
        border-bottom: 0;
      }
    }

    .nouislider {

      .noUi-handle {
        height: 2rem;
        width: 2rem;
        background-color: $tch-blue;
      }

      & + .thumb {
        background-color: $tch-blue;
      }
    }

    small {
      color: $tch-gray;
    }
  }

  // Radio groups

  .radio-group {

    [type="radio"] {

      & + label {
        top: 0;
        padding-left: 3rem;

        &::before,
        &::after {
          top: -.3rem;
          width: 2rem;
          height: 2rem;
        }
      }

      &:checked + label::after {
        background-color: $tch-blue;
        border: 0;
      }
    }
  }

  .form-footer {
    overflow: hidden;
    padding: .9rem;

    .save-button-container {
      display: inline-block;
      position: relative;
      float: right;

      .preloader-wrapper {
        position: relative;
        top: .5rem;
      }

      .status-text {
        display: inline-block;
        position: relative;
        top: 50%;
        @include translate(0, -50%);
        line-height: normal;
      }

      .btn[type="submit"],
      .btn[name="view-notes"] {
        margin-left: 1rem;
      }
    }

    .btn[type="submit"] {
      @include respond(mobile) {
        width: 100%;
      }
    }
  }

  &.form--autocomplete-search {

    .autocomplete-content {

      li {
        min-height: auto;
        padding: 1rem;
        cursor: pointer;
        @include transition(background-color $default-transition-easing $default-transition-time);

        &:hover,
        &:focus {
          background-color: rgba(69, 103, 182, 0.05);

          & > i {
            color: $tch-blue;
          }
        }

        &:active {
          color: $tch-black;
          background-color: rgba(69, 103, 182, 0.15);

          & > i {
            color: $tch-blue;
          }
        }

        span.text {
          line-height: normal;
          padding: 0;
          color: $tch-blue;
        }

        i {
          position: relative;
          top: -.1em;
          color: $tch-gray-light;
          vertical-align: middle;
          margin-right: .5rem;
          @include transition($default-transition);
        }

        img {
          display: none;
        }

        .highlight {
          color: $tch-gold;
        }
      }
    }
  }

  .input-reveal {

    .reveal-target {
      display: none;
    }
  }
}

.main-container form {
  @include respond(mobile) {
    padding-left: $mobile-gutter;
    padding-right: $mobile-gutter;
  }
}

.footer-continue {
  margin-top: $padding-small;
  text-align: center;
}

.file-upload-overlay {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  @include transition(all .4s ease-out);

  &::after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .preloader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
  }
}

/* Auth */

body.auth {

  .input-remember-me {
    @include respond(mobile) {
      margin-top: 0;
      margin-bottom: .5rem;
      text-align: center;
    }

    label[for="_remember_me"] {
      display: inline-block;
      left: auto;
      padding-left: 2rem;
      font-size: .8em;
    }
  }

  .forgot-password,
  .activation-alert {
    font-size: .9em;
  }
}

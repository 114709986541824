body.patient {

  .hero {
    position: relative;
    margin-bottom: $padding-small;
    padding: 8rem $padding-medium 2rem;
    background-size: cover;
    background-position: 50% 50%;
    @include overlay();
    @include respond(mobile) {
      padding: 7rem $padding-small $padding-small;
    }

    .tagline {
      margin-bottom: 2rem;
      line-height: normal;
      color: $tch-white;
      font-family: $display-font;
      font-style: italic;
      font-size: 3em;
      text-shadow: 0 1px 0 $tch-black;
      @include respond(mobile) {
        font-size: 8vw;
      }
    }

    .btn {
      @include respond(mobile) {
        font-size: 4.25vw;
        width: 100%;
      }

      i {
        @include respond(mobile) {
          font-size: 4vw;
        }
      }

      + .btn {
        margin-left: 1rem;

        @include respond(tablets) {
          margin-top: 1rem;
          margin-left: 0;
        }
      }
    }
  }

  &.home .main-container {

    & > section {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .upcoming-appointments {

    .appointment {

      a {
        text-align: left;
      }
    }
  }

  .create-appointment {

    header {
      margin-bottom: $padding-small;
    }

    .step-title {
      margin: 0;
      font-family: $sans-font;
      font-style: normal;
      font-weight: 400;
      font-size: 2em;
      @include respond(mobile) {
        font-size: 7vw;
      }
    }

    .step-indicator {
      display: inline-block;
      position: absolute;
      bottom: 1.2rem;
      right: 0;
      margin-left: .5rem;
      @include respond(mobile) {
        right: $mobile-gutter;
      }

      &::after {
        content: "";
        display: block;
        width: 105%;
        height: 0;
        padding-bottom: 100%;
        border: 1px solid $tch-gold;
        border-radius: 50%;
      }

      .step-text {
        float: left;
        width: 105%;
        margin-top: -0.6em;
        padding: 50% .5em 0 .5em;
        font-size: .4em;
        font-weight: 400;
        text-align: center;
        color: $tch-gold;

        strong {
          font-weight: 900;
        }
      }
    }

    .add-dependent {
      @include respond(mobile) {
        margin-left: $mobile-gutter;
        margin-right: $mobile-gutter;
      }

      a {
        font-size: 1.25em;
        font-family: $sans-font;
        font-weight: 300;
      }

      i {
        position: relative;
        top: .35rem;
      }
    }

    .form--select-location {

      input[type="text"] {
        font-size: 1.5em;
      }

      .btn.float {
        @include respond(mobile) {
          display: block;
          position: relative;
          margin: 0 auto 1rem;
          width: 6rem;
        }
      }

      .timezone-display,
      .current-time-display {
        @include respond(mobile) {
          text-align: center;
        }
      }
    }

    .form--confirm-datetime {

      .selected-datetime {
        padding: 1rem $padding-small;
        font-size: 1.25em;
        line-height: normal;
        background-color: darken($tch-white, 5%);

        p {
          margin: .5rem;
        }
      }

      .selected-datetime-action {
        overflow: hidden;
      }
    }

    .form--encounter-type {

      .input-field {
        margin: 0;
      }
    }

    #enter-phone-number .phone-alert {
      display: none;
    }

    &.done {

      .status {
        margin-bottom: 3rem;
        font-size: 1.25em;

        strong {
          font-size: 1.2em;
        }
      }

      .btn-large-square {
        width: 50%;
        margin: 0 auto 3rem auto;
        @include respond(mobile) {
          width: auto;
        }
      }
    }
  }
}

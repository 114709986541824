@import "common/variables";

// Bootstrap
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_alerts.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_alerts.scss";

// Materialize
@import "../../bower_components/Materialize/sass/materialize.scss";

// Font Awesome
@import "../../bower_components/font-awesome/scss/font-awesome.scss";

@import "common/responsivity";
@import "common/global";
@import "common/fonts";
@import "common/dewdrop";
@import "common/page-transitions";
@import "components/buttons";
@import "components/waves";
@import "components/forms";
@import "components/flyouts";
@import "components/dropdowns";
@import "components/chips";
@import "components/tabs";
@import "components/collapsible";
@import "components/modals";
@import "components/navs";
@import "components/tables";
@import "components/calendars";
@import "components/notices";
@import "components/toasts";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/user-settings";
@import "layouts/patients";
@import "layouts/physicians";
@import "layouts/encounter";
@import "layouts/help";
@import "layouts/appointments";
@import "layouts/auth";

.flyout-container {
  position: relative;
}

.flyout {
  @extend .z-depth-2;
  display: none;
  position: absolute;
  z-index: 100002 !important;
  width: 10rem;
  padding: 1rem;
  background-color: $tch-white;
  -webkit-overflow-scrolling: touch;
          overflow-scrolling: touch;
  @include respond(mobile) {
  }

  .container {
    width: 100%;
  }

  .button-list {

    li {
      margin: .5rem 0;
    }

    .btn-small {
      padding: .5rem;
      font-size: .8em;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    @include translate(0, -50%);
    height: 0;
    width: 0;
    border-style: solid;
    border-color: $tch-white;
  }

  &.flyout-left {
    left: -12rem;
    @include respond(mobile) {
      top: auto;
      bottom: 100%;
      left: 50%;
      @include translate(-50%, 0);
    }

    &::after {
      right: -1rem;
      border-left: 1rem solid $tch-white;
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      @include respond(mobile) {
        top: auto;
        left: 50%;
        right: auto;
        bottom: -1rem;
        @include translate(-50%, 0);
        border-top: 1rem solid $tch-white;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 0;
      }
    }
  }
}

/**
 * Flyout specific
 */

.export-appointment-flyout {
  top: 50%;
  @include translate(0, -50%);
  width: 14rem;
  text-align: center;
  @include respond(mobile) {
    width: 85%;
  }
}

.chip {
  $size: 3rem;
  display: block;
  position: relative;
  height: $size;
  line-height: $size;
  padding: 0 2rem 0 4rem;
  font-size: 1.2rem;
  border-radius: 5rem;

  &::before {
    content: "account_circle";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 3rem;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}

body.auth {

  .hra-ctas {
    margin: 2rem 0 6rem 0;
    padding: 2rem 1rem;
    border: 2px solid $tch-blue;
    border-radius: 4px;
    overflow: hidden;

    @include respond(mobile) {
      width: auto;
      margin-left: $mobile-gutter;
      margin-right: $mobile-gutter;
    }

    header {
      text-align: center;

      .hra-ctas-title {
        margin: 0 0 .5rem 0;
        font-size: 1.5em;
        color: lighten($tch-blue, 15%);
      }

      .hra-ctas-subtitle {
        display: block;
        margin-bottom: .5rem;
      }
    }

    .hra-ctas-buttons {
      text-align: center;

      .btn {
        margin: 1rem 1rem 0 1rem;
      }
    }
  }

  .hra-login-title {
    font-size: 1.35em;
    font-style: normal;
    color: rgba(0,0,0,0.87);
  }
}

.toast {
  color: $tch-black;
  font-family: $sans-font;
  background-color: #fcf8e3;
  @include respond(mobile) {
    padding-left: $mobile-gutter;
    padding-right: $mobile-gutter;
  }

  a:not(.btn) {
    color: $tch-blue;
    font-size: .9em;
    font-weight: 700;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      color: $tch-black;
    }
  }

  .btn {
    margin-left: .5rem;
    padding: .2em .3em;
    border-width: 1px;
    font-size: .8em;
  }

  i:first-child {
    top: .15em;
    margin-right: .25rem;
  }

  .i-alert {
    margin-right: .5rem;
  }

  .toast-close {
    display: inline-block;
    margin-left: .3rem;
    padding: .1rem .2rem;

    i {
      position: relative;
      top: .1em;
      font-size: 1em;
      color: $tch-red;
      margin: 0;
    }
  }

  &.toast--appointment {
    color: #fcf8e3;
    background-color: $tch-black;

    .toast-text {
      word-break: break-word;
      @include respond(mobile) {
        font-size: .8em;
        width: 66%;
        line-height: 1.2em;
      }
    }
  }

  &.toast--start-session {
    margin: 0 6rem 0 0;
    background-color: transparent;
    box-shadow: none;
    @include respond(tablets) {
      padding-bottom: 1.25rem;
      background-color: rgba(255, 255, 255, .9);
    }

    .btn {
      margin-left: 0;
      padding: 1rem 2rem;
      color: $tch-white;
      font-size: 1em;
      font-family: $body-font;

      &:hover,
      &:active,
      &:focus {
        color: $tch-white;
      }

      i:first-child {
        top: .15em;
        margin-right: .25rem;
      }

      i:last-child {
        top: .25em;
      }
    }
  }
}

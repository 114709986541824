// Appointment blocks

.appointment {

  &.canceled {
    background-color: $tch-red-lightest;
  }

  .appointment-info {
    font-family: $sans-font;
    font-weight: 300;
    color: $tch-gray-light;

    li {
      margin-bottom: 1rem;

      p {
        margin: .5rem 0;
      }

      i {
        top: 0;
        margin-right: 1rem;
        font-size: 2em;
        width: 1rem;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}

.view-appointment {

  .appointment-info {
    margin-bottom: 2rem;
    font-size: 1.25em;
    color: $tch-gray-light;
    @include respond(mobile) {
      text-align: center;
    }

    & > p {
      margin: .75rem 0;
    }

    .chip {
      display: inline-block;
    }

    .btn-phone {
      float: right;
      color: $tch-blue;
      @include respond(mobile) {
        float: none;
      }
    }

    .waiting-time {
      color: $tch-orange;
    }

    .appointment-encounter-type {

      .btn-phone {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .appointment-details {

    li {
      margin: 0;

      &.past-encounters .collapsible-body {
        padding-top: $padding-small;
      }
    }
  }

  .form-footer {
    margin-top: 2rem;
    padding-bottom: 1rem;
    overflow: hidden;

    .btn {
      float: right;
      margin-right: 1rem;
      @include respond(mobile) {
        display: block;
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 1rem;
      }

      &:first-child {
        margin-right: 0;
      }

      &.btn-large-square {
        width: 50%;
        float: none;
        margin: 2rem auto;
        @include respond(mobile) {
          width: auto;
        }
      }

      i.fa {
        font-size: 1rem;
        top: 0;
      }
    }
  }
}

.appointments-list {
  @extend .select-list;
}

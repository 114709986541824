body.encounter {

  .status--loading {
    background-color: $tch-gold;
  }

  .status--connected {
    background-color: $tch-green;
  }

  .status--disconnected {
    background-color: $tch-red;
  }

  #app-header {

    .row .col {
      position: relative;
      height: 4.5rem;
      @include respond(mobile) {
        &:first-child,
        &:last-child {
          padding: 0 .75rem;
        }
      }
    }

    .app-title img {
      margin-bottom: .65rem;
    }

    .other-encounter-name {
      display: block;
      position: relative;
      top: 50%;
      @include translate(0, -50%);
      line-height: 1em;
      text-align: center;
      font-size: 1.5em;
      @include respond(mobile) {
        font-size: 6vw;
      }
    }

    .other-encounter-status {
      display: inline-block;
      position: relative;
      top: 50%;
      @include translate(0, -50%);
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
    }
  }

  .encounter-status {
    margin-top: 2rem;
    text-align: center;

    i {
      font-size: 10rem;
    }
  }

  .encounter-loading {

    .title,
    .loading-note {
      text-align: center;
    }

    .title {
      margin-bottom: 1rem;
      text-transform: uppercase;
      @include respond(mobile) {
        font-size: 8vw;
      }
    }

    .loading-note {
      margin-bottom: 4rem;
    }
  }

  .encounter-session {

    &.video {

      .emergency-911-notice {
        background: transparent;
      }
    }

    .loading-note {
      font-family: $sans-font;
      font-size: 2.25em;
      font-weight: 300;
      line-height: normal;
      color: $tch-gold;
      @include respond(mobile) {
        font-size: 8vw;
      }

      small {
        display: inline-block;
        margin-top: 1rem;
        font-size: 50%;
        color: $tch-gold-darker;
      }
    }

    .emergency-911-notice {
      margin-top: 2rem;
      padding: .5rem 1rem;
      color: $tch-red;
      border: 0;
    }

    #subscriber { // other person
      height: 100%;
      width: 100%;
      background-color: $tch-black; // dev

      .not-connected {
        text-align: center;
        @include respond(mobile) {
          width: 100%;
          padding-left: $mobile-gutter;
          padding-right: $mobile-gutter;
        }

        i.animate--spin {
          font-size: 6em;
        }

        .btn {
          margin: .5rem 0;
        }
      }
    }

    #publisher { // user
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
      height: 7rem;
      width: 7rem;
      background-color: $tch-gray;

      .OT_mini {
        height: 100% !important;
        width: 100% !important;
      }
    }

    .user-encounter-status {
      display: inline-block;
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      z-index: 10;
    }

    &.phone {

      .phone-interaction {
        padding-top: 6rem;
      }

      .status i {
        font-size: 10rem;
      }

      .alert {
        display: inline-block;
        margin-top: 1rem;
      }

      .phone-call-cta {
        margin: 3rem 0;
        font-size: 1.2em;

        i {
          top: .1em;
        }
      }
    }
  }

  // Patient
  &.patient {

    .encounter-session.video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  // Physician
  &.physician {
    height: 100%;

    #app-header {

      .app-title {
        position: relative;
        height: 100%;
        text-align: left;

        img {
          position: relative;
          top: 50%;
          @include translate(0, -50%);
          margin: 0;
        }
      }

      .btn {
        float: right;
        margin-top: .5rem;
      }
    }

    #main,
    .main-container,
    .encounter-session,
    .encounter-session > .row,
    .encounter-session > .row > .col {
      height: 100%;
    }

    .main-container {
      max-width: none;
      width: auto;
      padding-bottom: 0;
    }

    #subscriber {

      .not-connected .loading-note {
        font-size: 1.5em;
      }
    }

    .encounter-session {

      & > .row {
        margin: 0;

        & > .col {
          position: relative;
          padding: 0;

          &.ui-resizable {
            right: auto !important;
          }

          .content-padding {
            @include respond(mobile) {
              padding-left: $mobile-gutter;
              padding-right: $mobile-gutter;
            }
          }
        }
      }

      .encounter-session--patient-appointment-info {
        overflow-y: scroll;

        .tabs-container {

          .tabs {
            margin-top: 4rem;
            @include respond(mobile) {
              margin-top: 2rem;
            }
          }

          .tab-content {

            .tabs {
              margin-top: 0;

              .tab a {
                font-size: 1em;
              }
            }
          }

          .patient-header {
            display: none;
          }

          .alert {
            display: block;
          }
        }

        .past-encounters {

          .page-header {
            display: none;
          }
        }
      }

      .encounter-session--interaction {

        .other-encounter-name {
          position: absolute;
          width: 100%;
          padding: .5rem;
          text-align: center;
          color: white;
          background-color: rgba(0, 0, 0, 0.8);

          p {
            margin: 0;
            line-height: normal;
          }

          i {
            position: relative;
            top: -2px;
            margin-right: .25rem;
            vertical-align: middle;
          }

          .js-loadJoinersBirthdayAndAge {
            font-family: $sans-font;
            color: lighten($tch-gray, 20%);
          }
        }

        .action-screenshot {
          position: absolute;
          top: 50%;
          @include translate(0, -50%);
          right: 1rem;
          font-size: 1em;
          z-index: 1000;
          color: $tch-gold;

          i {
            vertical-align: middle;
            margin-left: .5rem;
            @include respond(mobile) {
              font-size: 2em;
            }
          }

          .action-screenshot-text {
            display: none;
          }
        }
      }
    }

    .status-switcher {
      display: none;
    }
  }
}

.encounter-end {

  .encounter-status {
    margin-top: 2rem;
    text-align: center;

    i {
      font-size: 10rem;
    }
  }

  .title,
  .loading-note {
    text-align: center;
  }

  .title {
    margin-bottom: 5rem;
    text-transform: uppercase;
    @include respond(mobile) {
      font-size: 8vw;
    }
  }

  .rating {

    .rating-title {
      margin: 0 0 1rem 0;
      color: $tch-gray-light;
      font-size: 1.5em;
      text-transform: none;
    }

    .star-rate {
      overflow: hidden;
      text-align: center;
      unicode-bidi: bidi-override;
      direction: rtl;

      a {
        display: inline-block;
        width: 3rem;
        margin: 0 .5rem;

        &:hover,
        &:active,
        &:focus,
        &.active {

          &,
          & ~ a {

            i {
              color: $tch-gold;
            }
          }
        }
      }

      i {
        width: 3rem;
        font-size: 3rem;
        color: $tch-gray;
        @include transition(all .1s $default-transition-easing);
      }
    }

    .rating-feedback {
      display: none;
      margin: 2rem 0 5rem 0;

      .form--rating-feedback {
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center;

        textarea {
          height: auto;
          padding: 1rem;
          font-family: $sans-font;
          font-size: 1.5em;
          background-color: lighten($tch-gray-light, 35%);
          border: 0;
        }
      }

      .rating-thanks {
        margin: 1rem 0;
        text-align: center;
        color: $tch-gray-light;
      }
    }
  }

  .encounter-session {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

#encounter-script {
  max-height: 75%;
  width: 50%;
  background-color: rgba(250, 250, 250, .8);
  @include respond(mobile) {
    width: 80%;
  }

  .modal-header {
    position: fixed;
    width: 100%;
    background: transparent;
  }

  .modal-content {
    font-family: $sans-font;
    font-style: italic;
  }

  .modal-main {
    padding-top: 4rem;

    .content {
      padding: 0 $padding-small;
      @include respond(mobile) {
        padding: 0;
      }
    }
  }

  .modal-close {
    display: block;
  }
}

.encounter-stream-self {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 150px;
    height: 150px;
    background: black;
    overflow: hidden;
    z-index: 10;
}

.view-encounter.view-appointment {

  .appointment-info {

    table {

      th {
        width: 20%;
        padding-right: $padding-small;
        font-family: $sans-font;
        font-weight: 300;
        font-size: .8em;
        text-transform: uppercase;
        color: lighten($tch-gray, 20%);
        text-align: right;
        @include respond(mobile) {
          width: 33%;
        }
      }
    }

    .waiting-time {
      color: $tch-gray-light;
    }

    .encounter-details {
      border-top: 1px dashed $tch-gray;
    }
  }
}

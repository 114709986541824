.collapsible {

  &.popout > li {
    box-shadow: none;
  }

  .collapsible-header {
    color: rgba(69, 103, 182, 0.5);
    -webkit-tap-highlight-color: rgba(255, 220, 150, .33);
    border-bottom: 2px solid $tch-blue;
    @include transition($default-transition);
    @include respond(mobile) {
      font-size: 4.5vw;
      padding: .5rem 1rem;
    }

    &:hover,
    &:active,
    &:focus {
      color: $tch-blue;
    }
  }

  .collapsible-body {
    padding: 0 2rem;
  }

  .active {

    .collapsible-header {
      color: $tch-blue;
      border-bottom-color: $tch-blue-dark;
    }
  }
}

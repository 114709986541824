// Responsive Mixins

@mixin respond($media) {

  @if $media == mobile {
    @media only screen and (max-width: '600px') { @content; }
  }

  @else if $media == tablets {
    @media only screen and (max-width: '767px') { @content; }
  }

  @else if $media == desktops {
    @media only screen and (min-width: '992px') { @content; }
  }

  @else if $media == large-desktops {
    @media only screen and (max-width: '1200px') { @content; }
  }

  @else if $media == small-height {
    @media only screen and (max-height: '320px') { @content; }
  }

  @else if $media == print {
    @media print { @content; }
  }

  @else {
    @media only screen and ('#{$media}') { @content; }
  }

}

.select-dropdown {

  .dropdown-button {
    position: relative;
    width: 100%;
    padding: 1rem 2rem 1rem 1rem;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    border: 1px solid rgba(0,0,0,0.26) !important;
    @include respond(mobile) {
      padding-left: .5rem;
      padding-right: .5rem;
    }

    i.prefix {
      top: -.1em;
    }

    i.arrow {
      position: absolute;
      top: 50%;
      @include translate(0, -50%);
      right: .5rem;
    }
  }
}

.dropdown-content {

  li a {
    color: $tch-blue;
  }
}

.btn {
  $btn-padding-top: .6rem;
  $btn-padding-bottom: .6rem;
  $border-width: .25rem;

  &:not(.btn-floating) {
    height: auto;
    line-height: normal;
    font-size: 1.2em;
    padding-top: $btn-padding-top + $border-width;
    padding-bottom: $btn-padding-top + $border-width;
    border-radius: .25rem;
    @include respond(mobile) {
      padding-top: ($btn-padding-top + .2rem) + $border-width;
      padding-bottom: ($btn-padding-top + .2rem) + $border-width;
    }

    // With icon
    i {
      position: relative;
      top: .2em;
      line-height: .5em;
      opacity: 0.6;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $tch-white;
  }

  // Primary (gold)
  &.btn-primary {
    color: $tch-black;
    background-color: $color-primary;

    &:hover, &:focus {
      color: $tch-black;
      background-color: lighten($color-primary, 5%);
    }

    &:active {
      color: $tch-black;
      background-color: darken($color-primary, 10%);
    }

    &.btn-clear {
      color: $color-primary;
      border-color: $color-primary;

      &:hover, &:focus {
        color: lighten($color-primary, 10%);
        border-color: lighten($color-primary, 10%);
      }

      &:active {
        color: $tch-gold-dark;
        border-color: $tch-gold-dark;
      }

      &.waves-effect .waves-ripple {
        background-color: rgba(216, 145, 4, 0.25);
      }
    }
  }

  // Secondary (blue)
  &.btn-secondary {
    background-color: $color-secondary;

    &:hover, &:focus {
      background-color: lighten($color-secondary, 5%);
    }

    &:active {
      background-color: darken($color-secondary, 10%);
    }

    &.btn-clear {
      color: $color-secondary;
      border-color: $color-secondary;

      &:hover, &:focus {
        color: lighten($color-secondary, 10%);
        border-color: lighten($color-secondary, 10%);
      }

      &:active {
        color: $tch-blue-dark;
        border-color: $tch-blue-dark;
      }

      &.waves-effect .waves-ripple {
        background-color: rgba(69, 103, 182, 0.25);
      }
    }
  }

  &.btn-red {
    background-color: $tch-red;

    &:hover, &:focus {
      background-color: lighten($tch-red, 10%);
    }

    &:active {
      background-color: darken($tch-red, 10%);
    }
  }

  &.btn-teal {
    background-color: $tch-teal;

    &:hover, &:focus {
      background-color: lighten($tch-teal, 10%);
    }

    &:active {
      background-color: darken($tch-teal, 10%);
    }
  }

  &.btn-green {
    background-color: $tch-green;

    &:hover, &:focus {
      background-color: lighten($tch-green, 5%);
    }

    &:active {
      background-color: darken($tch-green, 10%);
    }

    &.btn-clear {
      color: $tch-green;
      border-color: $tch-green;

      &:hover, &:focus {
        color: lighten($tch-green, 5%);
        border-color: lighten($tch-green, 5%);
      }

      &:active {
        color: darken($tch-green, 10%);
        border-color: darken($tch-green, 10%);
      }

      &.waves-effect .waves-ripple {
        background-color: rgba(3, 173, 3, 0.25);
      }
    }
  }

  &.btn-orange {
    background-color: $tch-orange;

    &:hover, &:focus {
      background-color: lighten($tch-orange, 10%);
    }

    &:active {
      background-color: darken($tch-orange, 10%);
    }
  }

  &.btn-purple {
    background-color: $tch-purple;

    &:hover, &:focus {
      background-color: lighten($tch-purple, 10%);
    }

    &:active {
      background-color: darken($tch-purple, 10%);
    }
  }

  &.btn-light-gray {
    background-color: $tch-gray-light;

    &:hover, &:focus {
      background-color: lighten($tch-gray-light, 10%);
    }

    &:active {
      background-color: darken($tch-gray-light, 10%);
    }

    &.btn-clear {
      color: $tch-gray-light;
      border-color: $tch-gray-light;

      &:hover, &:focus {
        color: lighten($tch-gray-light, 10%);
        border-color: lighten($tch-gray-light, 10%);
      }

      &:active {
        color: $tch-gray;
        border-color: $tch-gray;
      }
    }
  }

  &.btn-black {
    background-color: $tch-black;

    &:hover,
    &:focus {
      background-color: lighten($tch-black, 10%);
    }

    &:active {
      background-color: darken($tch-black, 10%);
    }

    &.btn-clear {
      color: $tch-black;
      border-color: $tch-black;

      &:hover,
      &:focus {
        color: lighten($tch-black, 10%);
        border-color: lighten($tch-black, 10%);
      }

      &:active {
        color: #000;
        border-color: #000;
      }
    }
  }

  // Clear versions
  &.btn-clear {
    padding-top: $btn-padding-top;
    padding-bottom: $btn-padding-bottom;
    background-color: transparent;
    border-width: $border-width;
    border-style: solid;
    @include respond(mobile) {
      padding-top: ($btn-padding-top + .2rem);
      padding-bottom: ($btn-padding-top + .2rem);
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }

    &.disabled,
    &:disabled {
      pointer-events: none;
      cursor: default;
      color: $tch-gray;
      border-color: lighten($tch-gray-light, 20%) !important;
      background-color: transparent !important;
    }

    &.btn-floating {

      i {
        position: relative;
        color: inherit;
        top: -.75rem;
        left: -.2rem;
      }
    }
  }

  &.btn-flat {
    background-color: transparent;
    border: 0;

    &:hover,
    &:active,
    &:focus {
      color: $tch-black;
      box-shadow: none;
    }

    &.disabled,
    &:disabled {
      pointer-events: none;
      cursor: default;
      color: $tch-gray;
    }
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    cursor: default;
    color: $tch-gray !important;
    background-color: lighten($tch-gray-light, 20%) !important;
    box-shadow: none !important;
  }

  &.btn-back {
    color: $tch-blue-dark;
    font-size: 1em;

    i {
      top: .3em;
      color: $color-secondary;
    }
  }

  &.btn-large-square {
    display: block;
    position: static;
    height: 100%;
    width: 100%;
    padding: $padding-small;
    font-size: 2em;
    @include respond(mobile) {
      font-size: 7vw;
    }

    i {
      display: block;
      font-size: 3em;
      margin-bottom: 3rem;
    }
  }

  // Sizes
  &.btn-medium {
    $btn-padding-top: ($btn-padding-top / 1.5);
    $border-width: ($border-width / 1.5);
    font-size: 1em;
    padding-top: $btn-padding-top + $border-width;
    padding-bottom: $btn-padding-top + $border-width;
    border-radius: .2rem;
    @include respond(mobile) {
      padding-top: ($btn-padding-top + .2rem) + $border-width;
      padding-bottom: ($btn-padding-top + .2rem) + $border-width;
    }

    i {
      font-size: 1.2em;
    }
  }

  &.btn-small {
    $btn-padding-top: ($btn-padding-top / 2);
    $border-width: ($border-width / 2);
    font-size: .9em;
    padding-top: $btn-padding-top + $border-width;
    padding-bottom: $btn-padding-top + $border-width;
    border-radius: (.25rem / 1.5);
    @include respond(mobile) {
      padding-top: ($btn-padding-top + .2rem) + $border-width;
      padding-bottom: ($btn-padding-top + .2rem) + $border-width;
    }

    i {
      font-size: 1em;
      top: .1em;
    }
  }
}

.btn-note {
  display: block;
  margin-top: .5rem;
  line-height: normal;
}

.button-list {
  @extend .blank-list;

  li {
    margin: 2rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.fixed-action-btn {
  position: fixed;
  z-index: 10001;
  bottom: 2rem;
  right: 2rem;
  transform: scaleY(0) scaleX(0);
  @include transition(transform .3s ease-in-out);
  @include respond(mobile) {
    bottom: 1rem;
    right: 1rem;
  }

  &.is-showing {
    transform: scaleY(1) scaleX(1);
  }

  &.fab-with-labels {

    ul.fab-list {
      width: 10rem;
      left: auto;
      right: .65rem;

      li.fab-item {
        position: relative;

        .left {
          position: relative;
          top: .5rem;
        }
      }

      a {
        display: block;
        padding: .75rem 0;
      }

      .fab-item-btn {
        float: right;
        transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px);
        opacity: 0;
        border-radius: 50%;
      }

      .fab-item-label {
        display: inline-block;
        position: relative;
        top: .25rem;
        transform: scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0px);
        opacity: 0;
        will-change: opacity, transform;
        @include transition(all .3s ease-out);
      }
    }
  }

  & > a.btn > i {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
  }

  // User status
  .user-status {

    &.status--available {
      @extend .btn-green;
    }

    &.status--in-session {
      @extend .btn-orange;
    }

    &.status--unavailable {
      @extend .btn-red;
    }

    &.status--on-call {
      @extend .btn-purple;
    }
  }
}

.fab-with-labels-overlay {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  @include transition(all .4s ease-out);

  &::after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

// Universal elements

::selection {
  color: $tch-white;
  background-color: $tch-gray;
}

::-moz-selection {
  color: $tch-white;
  background-color: $tch-gray;
}

a {
  color: $tch-blue;
  @include transition($default-transition);

  &:hover,
  &:active,
  &:focus {
    color: $tch-black;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

hr {
  clear: both;
  margin: 2rem 0;
  border: .5rem solid lighten($tch-gray, 45%);
}

em {
  color: $tch-gray;
}

strong {
  font-weight: 700;
}

// Colors

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.color-gold {
  color: $tch-gold;
}

.color-gray {
  color: $tch-gray;
}

.color-gray-light {
  color: $tch-gray-light;
}

.color-teal {
  color: $tch-teal;
}

.color-black {
  color: $tch-black;
}

.color-gold-dark {
  color: $tch-gold-dark;
}

.color-gold-darker {
  color: $tch-gold-darker;
}

.color-gold-light {
  color: $tch-gold-light;
}

.color-blue {
  color: $tch-blue;
}

.color-red {
  color: $tch-red;
}

.color-orange {
  color: $tch-orange;
}

.color-green {
  color: $tch-green;
}

.color-green-light {
  color: $tch-green-light;
}

.color-purple {
  color: $tch-purple;
}

.bg-gold {
  background-color: $tch-gold;
}

.bg-gray {
  background-color: $tch-gray;
}

.bg-teal {
  background-color: $tch-blue;
}

.bg-black {
  background-color: $tch-black;
}

.bg-gold-dark {
  background-color: $tch-gold-dark;
}

.bg-gold-darker {
  background-color: $tch-gold-darker;
}

.bg-gold-light {
  background-color: $tch-gold-light;
}

.bg-blue {
  background-color: $tch-blue;
}

.bg-red {
  background-color: $tch-red;
}

.bg-orange {
  background-color: $tch-orange;
}

.bg-green {
  background-color: $tch-green;
}

// Headers

h1, h2, h3, h4, h5, .title {
  font-size: 280%;
  line-height: normal;
}

.page-title {
  position: relative;
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
  font-family: $sans-font;
  font-weight: 300;
  border-bottom: 1px solid lighten($tch-gray-light, 30%);
}

h2, h4 {
  color: $tch-gray-light;
  font-family: $display-font;
  font-style: italic;
}

h2.title {
  font-size: 230%;
}

h3 {
  color: lighten($tch-gray-light, 20%);
  font-family: $sans-font;
}

h4,
h4.title {
  color: $tch-black;
  font-size: 1.5rem;
}

h5,
h5.title {
  margin-bottom: .5rem;
  font-size: 1.2rem;
}

// Body

html {
  height: 100%;
}

body {
  font-family: $body-font;
  @include transition(opacity $default-transition-easing $default-transition-time);
  @include transition(transform $default-transition-easing $default-transition-time);
}

#main {
  margin-top: 5rem;
}

.container {

  &.main-container {
    padding-top: 2rem;
    padding-bottom: $padding-small;
    @include respond(mobile) {
      max-width: auto;
      width: auto;
    }

    & > header,
    .page-header,
    .login-header {
      position: relative;

      & > * {
        @include respond(mobile) {
          padding-right: $mobile-gutter;
          padding-left: $mobile-gutter;
        }
      }
    }

    & > section {
      @include respond(mobile) {
        width: 100%;
        margin: 0 auto;
      }

      & > .content-padding {
        @include respond(mobile) {
          padding-left: $mobile-gutter;
          padding-right: $mobile-gutter;
        }
      }
    }

    &.no-top-padding {
      padding-top: 0 !important;
    }
  }
}

.hide-on-small-height {
  @include respond(small-height) {
    display: none !important;
  }
}

// Alerts

.alert {
  padding: 1rem;
  border: 0;
  @include respond(mobile) {
    line-height: normal;
    font-size: .8em;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  &.alert-info {
    color: $tch-black;
    background-color: rgba(69, 103, 182, .05);
    border-color: rgba(69, 103, 182, .15);
  }

  &.alert-info-2 {
    color: $tch-gray-light;
    font-style: italic;
    font-family: $sans-font;
    font-size: .8em;
    background-color: lighten($tch-gray-light, 38%);
  }

  &.emergency-911-notice {
    text-align: center;

    .btn {
      margin-left: .5rem;
      padding: .75rem 1.5rem;
    }
  }

  & > i {
    margin-right: .2em;
    vertical-align: middle;
  }

  small {
    display: inline-block;
    margin-top: .75rem;
    line-height: normal;
  }
}

// Mixins

@mixin overlay($type: 'dark', $opacity: 0.6) {

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @if 'dark' == $type {
      background-color: rgba(0,0,0,$opacity);
    } @else if 'light' == $type {
      background-color: rgba(255,255,255,$opacity);
    }
  }
}

// Tables

table.highlight > tbody > tr:hover {
  background-color: rgba(253, 181, 37, .05);
}

// Lists

.blank-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.scroll-list {
  overflow-y: scroll;
}

.select-list {

  li {
    text-align: left;
    border-radius: .25rem;

    a,
    a.btn {
      display: block;
      position: relative;
      margin: .5rem 0;
      padding: 1rem $padding-small;
      color: $tch-black;
      border: 1px solid rgba(69, 103, 182, 0.15);
      @include respond(mobile) {
        font-size: 6vw !important;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: rgba(69, 103, 182, 0.05);

        & > i {
          color: $tch-blue;
        }
      }

      &.active {
        color: $tch-black;
        background-color: rgba(69, 103, 182, 0.15);

        & > i {
          color: $tch-blue;
        }
      }

      &.disabled {
        position: relative;
        color: $tch-gray-light !important;
        border: 1px solid lighten($tch-gray-light, 35%);
        background-color: lighten($tch-gray-light, 38%) !important;

        &::after {
          content: "Full";
          position: absolute;
          top: 0;
          right: 0;
          padding: .25rem;
          font-size: .6em;
          font-family: $sans-font;
          color: $tch-gray-light;
          text-transform: uppercase;
          background-color: lighten($tch-gray-light, 30%);
          border-top-right-radius: .25rem;
        }
      }

      &.btn {
        text-align: left;
        text-transform: none;
        font-size: 1.2em;
      }

      i {
        top: 0;
        margin-right: .25rem;
        color: $tch-gray-light;
        font-size: .9em;
        width: 1rem;
        text-align: center;
        @include transition($default-transition);
        @include respond(mobile) {
          top: .1em;
          font-size: 1.2em;
          width: 1.4rem;

          &.fa-video-camera {
            font-size: 1.1em;
          }
        }
      }

      & > i {
        position: absolute;
        top: 50%;
        left: 1rem;
        margin-right: 0;
        @include translate(0, -50%);
        font-size: 2em;
        color: $tch-gray-light;
      }

      p {
        margin: .5rem 0;
      }
    }
  }
}

.main-container section > .select-list {
  @include respond(mobile) {
    margin-left: $mobile-gutter;
    margin-right: $mobile-gutter;
  }
}

.disclaimer {
  display: block;
  margin-top: .25rem;
  line-height: normal;
}

.photo-preview {

  .file-field {

    label {
      background-size: cover;
      background-position: 50% 50%;
      @include transition($default-transition);
    }
  }

  &.symptom-photos {

    .file-field {
      position: relative;

      &.input-active {

        label {
          background-color: rgba(69, 103, 182, .25);
          border-color: $tch-blue;

          i {
            color: $tch-blue;
          }
        }
      }

      i {
        font-size: 4em;
      }
    }

    i.remove {
      position: absolute;
      z-index: 1;
      top: .5rem;
      right: 1.25rem;
      color: $tch-red;
      font-size: 3em;
      opacity: .5;
      @include transition($default-transition);

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }

    label {
      display: block;
      text-align: center;
      position: relative;
      top: auto;
      left: 0;
      height: 10rem;
      cursor: pointer;
      background-color: rgba(120, 120, 122, .25);
      border: .25rem solid $tch-gray;

      i,
      .preloader-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
      }

      i {
        margin: -0.525em 0 0 -0.525em;
        color: $tch-gray;
      }

      .preloader-wrapper {
        margin: -19px 0 0 -19px;
      }
    }

    input[type="file"] {
      position: absolute;
      z-index: -1;
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
    }
  }
}

.ui-helper-hidden-accessible {
  display: none;
}

// Status coloring

.status-item {
  @include transition($default-transition);

  &.appointment {
    background-color: lighten($tch-gray-light, 40%);

    &:hover,
    &:active,
    &:focus,
    &.active {
      background-color: lighten($tch-gold-light, 15%);

      .appointment-status {
        background-color: rgba(253, 181, 37, .75);
      }
    }

    &.status--in-session {
      background-color: lighten($tch-gray-light, 30%);

      .appointment-status {
        background-color: lighten($tch-gray-light, 30%);
      }

      i,
      .status-title {
        color: $tch-green;
        font-weight: 700;
      }
    }

    &.status--done {
      background-color: lighten($tch-gray-light, 30%);

      .appointment-status {
        background-color: lighten($tch-gray-light, 15%);
      }

      i,
      .status-title {
        color: $tch-gray;
      }
    }
  }

  &.status--available {
    background-color: desaturate(lighten($tch-green, 55%), 25%);

    .appointment-status {
      background-color: rgba(3, 173, 3, .75);
    }

    &.chip i {
      color: $tch-green;
    }
  }

  &.status--overdue,
  &.status--unavailable {
    background-color: desaturate(lighten($tch-red, 40%), 25%);

    .appointment-status {
      background-color: rgba(255, 0, 0, .75);
    }

    &.chip i {
      color: $tch-red;
    }
  }

  &.status--waiting,
  &.status--in-session {
    background-color: desaturate(lighten($tch-orange, 30%), 25%);

    .appointment-status {
      background-color: rgba(249, 135, 41, .75);
    }

    &.chip i {
      color: $tch-orange;
    }
  }

  &.status--on-call {
    background-color: desaturate(lighten($tch-purple, 45%), 10%);

    &.chip i {
      color: $tch-purple;
    }
  }

  &.status--scheduled {
    background-color: desaturate(lighten($tch-blue, 40%), 10%);

    .appointment-status {
      background-color: rgba(69, 103, 182, .75);
    }
  }
}

// Patient/dependent dropdown

.patient-switcher,
.page-header > .add-dependent {
  position: absolute;
  top: .5rem;
  right: 0;
  width: 33%;
  @include respond(mobile) {
    position: static;
    width: auto;
  }

  i {
    position: relative;
    vertical-align: middle;
  }
}

.page-header > .add-dependent {
  padding: 1rem;
  text-align: right;
}

.add-dependent {

  a {
    font-size: 1em;
    font-family: $sans-font;
    font-weight: 300;

    i {
      font-size: 1.2em;
    }
  }
}

// Done & Confirm pages' status + info

.create-appointment.done,
.confirm-appointment {

  .status {
    text-align: center;
    @include respond(mobile) {
      margin-left: $mobile-gutter;
      margin-right: $mobile-gutter;
    }

    i {
      font-size: 10rem;
    }
  }

  .appointment-info {
    margin: 4rem 0;
    padding: 0 10%;
    font-size: 1.2em;
    @include respond(mobile) {
      margin: 2rem 0;
      padding: 0;
    }

    ul {
      text-align: left;
      @include respond(mobile) {
        text-align: center;
      }
    }

    .appointment {

      a {
        font-size: 1.5em;
        @include respond(mobile) {
          font-size: 6vw;
        }

        p {
          margin: 0;
          text-align: left;
          line-height: 1.5em;
        }
      }

      i {
        top: auto;
        font-size: 1em;
        width: 1em;
      }
    }

    .appointment-info-actions {
      text-align: right;
      @include respond(mobile) {
        text-align: center;
      }

      a {
        display: block;
        margin: .5rem 0;
        @include respond(mobile) {
          display: inline-block;
        }
      }
    }
  }

  .cal-export-container {
    @include respond(mobile) {
      margin-left: $mobile-gutter;
      margin-right: $mobile-gutter;
    }
  }
}

// Carousel

.carousel {

  a.carousel-item {
    @include transition(none);
  }
}


// HRA score

.score-bubble {
  display: inline-block;
  position: relative;
  float: right;
  text-align: center;
  font-family: $sans-font;

  &::before {
    content: attr(data-name);
    position: absolute;
    width: 100%;
    bottom: -2rem;
    font-weight:400;
  }

  &::after {
    content: attr(data-percent);
    position: absolute;
    width: 100%;
    top: 3.7rem;
    left: 0;
    font-size: 2rem;
    text-align: center;
  }

  svg {
    width: 5rem;
    height: 5rem;

    &:nth-child(2) {
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate(-90deg);

      path {
        fill: none;
        stroke-width: 25;
        stroke-dasharray: 629;
        stroke: $tch-gray;
        opacity:.9;
        animation: progress-load 10s;
      }
    }
  }

  .score-poor {
    color: $tch-red;
    border-color: $tch-red;
  }

  .score-fair {
    color: $tch-orange;
    border-color: $tch-orange;
  }

  .score-good {
    color: $tch-green-light;
    border-color: $tch-green-light;
  }

  .score-excellent {
    color: $tch-green;
    border-color: $tch-green;
  }
}

// Animations

// Loading spinner

.preloader-wrapper.active {

  .spinner-gold,
  .spinner-gold-only {
      border-color: $tch-gold;
      -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                         spinner-first-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
              animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                         spinner-first-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .spinner-gray,
  .spinner-gray-only {
      border-color: $tch-gray;
      -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                         spinner-second-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
              animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                         spinner-second-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }

  .spinner-teal,
  .spinner-teal-only {
      border-color: $tch-blue;
      -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                         spinner-third-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
              animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                         spinner-third-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  }
}

@-webkit-keyframes spinner-first-fade-in-out {
  from { opacity: 1; }
  25% { opacity: 1; }
  26% { opacity: 0; }
  89% { opacity: 0; }
  90% { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes spinner-first-fade-in-out {
  from { opacity: 1; }
  25% { opacity: 1; }
  26% { opacity: 0; }
  89% { opacity: 0; }
  90% { opacity: 1; }
  100% { opacity: 1; }
}

@-webkit-keyframes spinner-second-fade-in-out {
  from { opacity: 0; }
  15% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  51% { opacity: 0; }
}

@keyframes spinner-second-fade-in-out {
  from { opacity: 0; }
  15% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  51% { opacity: 0; }
}

@-webkit-keyframes spinner-third-fade-in-out {
  from { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  76% { opacity: 0; }
}

@keyframes spinner-third-fade-in-out {
  from { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  76% { opacity: 0; }
}

// Element spin

.animate--spin {
  -webkit-animation:spin 4s linear infinite;
     -moz-animation:spin 4s linear infinite;
          animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

// Loading

@keyframes progress-load { 0% { stroke-dashoffset: 0; } }


// Page transitions

.page-load {

}

// Google Autocomplete
.pac-container {
    z-index: 10000000;

    .pac-item {
      font-family: $sans-font;

      .pac-matched {
        font-size: 1.2em;
      }
    }

    .pac-icon-marker {
      @extend .material-icons;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: .9em;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      background-image: none;
    }
}

// Resizing

.ui-resizable-handle {
  display: block;
  position: absolute;
  font-size: 0.1px;
  -ms-touch-action: none;
      touch-action: none;
  @include respond(mobile) {
    display: none !important;
  }

  &.ui-resizable-e {
    cursor: ew-resize;
    height: 100%;
    width: 1rem;
    top: 0;
    right: 0;
  }

  .ui-resizable-handle-icon {
    position: absolute;
    top: 50%;
    left: 0;
    @include translate(0, -50%);
    height: 3rem;
    width: 100%;
    background-color: $tch-gray;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -50%);
      color: $tch-white;
    }
  }
}

// Positioning

.center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

// PDFs

.pdf-container {
  height: 60vh;

  @include respond(mobile) {
    margin-left: $mobile-gutter;
    margin-right: $mobile-gutter;
  }
}

.pdf-download {

  @include respond(mobile) {
    margin-left: $mobile-gutter;
    margin-right: $mobile-gutter;
  }

  i {
    position: relative;
    top: 8px;
  }
}

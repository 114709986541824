.nav {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline;
  }
}

#app-nav {

  .side-nav {
    color: $tch-white;
    background-color: darken($tch-gray, 30%);

    li {

      & > span {
        display: block;
        padding: 1rem $padding-small;
        line-height: 1em;
        color: darken($tch-gray, 15%);
        font-family: $sans-font;
        font-size: .85em;
        letter-spacing: .25em;
        border-top: 1px solid $tch-black;
      }

      & > form {
        padding: 0 2rem;

        input {
          width: 170px;
          margin: 0;
          padding: 0 1rem;
          font-family: $sans-font;
          font-weight: 400;
          font-size: .9em;
          color: $tch-gray-light;
          background: darken($tch-gray, 25%);
        }
      }
    }

    a {
      height: auto;
      line-height: 4.5em;
      padding: 0 $padding-small;
      color: $tch-white;
      font-size: 1em;
      text-transform: uppercase;
      @include respond(mobile) {
        font-size: 1.2em;
      }

      &:hover,
      &:active,
      &:focus {
        color: $tch-gold;
        background-color: $tch-black;

        i {
          color: $tch-gold-light;
        }
      }

      i {
        position: relative;
        top: .25em;
        height: auto;
        width: 1em;
        margin-right: .25em;
        text-align: center;
        font-size: 2em;
        color: $tch-gold;
      }
    }

    .sub-nav {
      $subNavPadding: 2rem;
      position: relative;
      margin-bottom: 1rem;
      margin-left: $subNavPadding;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: $subNavPadding / 2;
        height: 100%;
        width: 1px;
        background-color: darken($tch-gold-darker, 10%);
      }

      a {
        line-height: 3em;
        text-transform: none;
      }

      i {
        top: .75em;
        height: 1em;
        line-height: normal;
        margin-right: .5em;
        font-size: 1.2em;
      }
    }

    li.help {

      a {
        line-height: 2em;
      }
    }

    .muted {

      a {
        color: $tch-gray;

        i {
          color: $tch-gray;
        }
      }
    }

    ul.muted {
      margin-top: 1rem;

      a {
        font-size: .9em;

        i {
          top: 1em;
          line-height: normal;
          font-size: 1.5em;
        }
      }
    }
  }
}

.bottom-nav {
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $tch-white;
  box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.16),
              0 -2px 10px 0 rgba(0,0,0,0.12);

  & > .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
  }

  .col {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 0;
    border-right: 1px solid $tch-gray-light;

    &:last-child {
      border-right: 0;
    }
  }

  a.btn {
    display: block;
    padding: $padding-small;
    text-align: center;
    font-size: .9em;
    border-radius: 0;
    @include respond(mobile) {
      padding: 1rem;
      font-size: 2.6vw;
    }

    &:hover,
    &:active,
    &:focus {
      color: $tch-gold;
    }

    &.active {
      color: $tch-gold-dark;
      background-color: desaturate(lighten($tch-gold, 40%), 25%);
    }

    i {
      display: block;
      top: 0;
      margin-bottom: .75rem;
      font-size: 2em;
    }
  }
}

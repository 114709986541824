.ftr-logic-wrapper {
  display: none;
}

.filter-form {
  margin-bottom: 15px;
}

.filter-form .filter-row .btn {
  font-size: .6em;
}

.filter-form .footer .btn {
  font-size: .8em;
}

.filter-form fieldset {
  border: none;
  padding: 0;
}

.filter-form .filter-row input {
  margin: 0;
  font-size: 100%;
  height: auto;
}
